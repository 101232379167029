<template>
	<v-app app class="default-layout">
		<div class="px-3 app-bar" ref="appBarRef">
			<v-app-bar
				app
				flat
				class="rounded"
				:height="height"
				:dark="$theme.dark"
				:color="$vuetify.theme.isDark ? '' : 'white'"
			>
				<v-app-bar-nav-icon class="md:tw-hidden" @click="$emit('drawer')" />
				<img
					@click="$route.path !== '/' && $router.push('/')"
					class="tw-ms-16 tw-cursor-pointer"
					src="@/assets/images/nav-logo.png"
					alt
				/>

				<v-spacer></v-spacer>

				<v-menu
					v-if="$currentUser"
					v-model="menuCurrentUser"
					:close-on-content-click="false"
					right
					offset-y
					rounded="b"
					width="100%"
					max-width="350"
					class="tw-z-20"
					nudge-bottom="8"
					transition="slide-y-transition"
				>
					<template #activator="{ attrs, on }">
						<AppBarMenuActivator v-on="on" v-bind="attrs" />
					</template>
					<v-card>
						<v-divider />
						<v-list dense class="transparent">
							<v-list-item :disabled="$loading" @click="handleLogout">
								<v-list-item-icon>
									<icon-exit />
								</v-list-item-icon>
								<v-list-item-content>
									<v-list-item-title v-text="'Logout'" />
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-card>
				</v-menu>
			</v-app-bar>
			<v-progress-linear
				top
				left
				absolute
				height="3"
				indeterminate
				color="secondary"
				v-if="$loading"
			/>
		</div>

		<!-- Main content / router -->
		<v-main
			class="default-layout__main"
			:class="$theme.dark ? 'darken-4' : 'lighten-1'"
		>
			<div class="tw-flex h-content tw-overflow-hidden">
				<router-wrapper class="tw-pb-2 lg:tw-pb-10">
					<router-view
						class="router-view tw-flex-1 tw-px-4 md:tw-px-8 lg:tw-px-20 tw-flex tw-flex-col tw-overflow-auto"
					/>
					<slot />
				</router-wrapper>
			</div>
		</v-main>

		<dialog-logout />
	</v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import RouterWrapper from '@/components/basic/RouterWrapper.vue'
import DialogLogout from '../components/dialogs/DialogLogout.vue'
import AppBarMenuActivator from '../components/CurrentUser/AppBarMenuActivator.vue'

export default {
	name: 'ExtraLayout',
	components: {
		DialogLogout,
		RouterWrapper,
		AppBarMenuActivator,
	},
	data() {
		return {
			height: 0,
			isLoaded: false,
			menuCurrentUser: false,
			dialogCurrentUser: false,
		}
	},
	computed: {
		...mapGetters(['$drawer', '$loading', '$theme']),
		...mapGetters('auth', ['$isAuth', '$currentUser', 'isContractSigned']),
		...mapGetters('settings', ['$currentLanguage']),
	},
	mounted() {
		this.height = this.$refs.appBarRef.getBoundingClientRect().height + 16
		document.documentElement.style.cssText += `--header-height:${this.height}px;`
	},
	methods: {
		...mapMutations(['SET']),
		...mapActions('auth', ['logout', 'fetchProfile', 'updateCurrentUser']),
		...mapActions(['toggleDrawer', 'toggleLoading', 'toggleTheme']),

		handleLogout() {
			this.SET({
				logout: {
					dialog: true,
					isLoading: false,
				},
			})
		},
		onChangeTheme() {
			const theme = !this.$theme.dark
			this.$vuetify.theme.dark = theme
			this.toggleTheme(theme)
		},
	},
}
</script>
